<template>
  <q-card class="row no-wrap fullSize">
    <q-card class="col">
      <div v-if="wordviewer.showContent" class="WordContainer">
        <!-- An IFrame would use ugly css and is not readable in darkmode. Chrome does not allow to set color css in an iframe -->
        <div
          id="leftViewer"
          v-html="wordviewer.html"
          class="wordContent"
          @click="(evt) => wordviewer.wordClicked(evt)"
        ></div>
      </div>
    </q-card>
    <q-card v-show="compareWith != null" class="col q-ml-md">
      <div v-if="wordviewer.showCompareContent" class="WordContainer">
        <div
          id="rightViewer"
          v-html="wordviewer.htmlCompare"
          class="wordContent"
          @click="(evt) => wordviewer.wordClicked(evt)"
        ></div>
      </div>
    </q-card>
  </q-card>
</template>

<script lang="coffee">
  import { defineComponent, ref, onMounted, reactive, onUnmounted,watch } from 'vue'
  import { useQuasar } from 'quasar'
  import { Wordviewer  } from "./wordviewerCls.coffee"
  export default defineComponent({
    emits:[]
    name: 'wordrenderer',
    props:
      instancename:
        type: String
        default: 'wordrenderer'
      file:
        type: Object
        default:null
      resource:
        type: Object
        default:null
      compareWith:
        type: Object
        default: null
    setup: (props,{emits}) ->
      q = useQuasar()
      refs={}
      wordviewer = reactive(new Wordviewer({props:props,emit:emits,refs:refs,quasar:q}))
      wordviewer.vueBinder()
      onMounted(() => wordviewer.mounted())
      onUnmounted(() => wordviewer.unmounted())

      watch(() =>
        props.resource
      ,(current,old) =>
        wordviewer.changeRevision(current)
      ,{immediate:true}
      )

      watch(() =>
        props.compareWith
      ,(current,old) =>
        wordviewer.changeCompare(current)
      ,{immediate:true}
      )
      return {wordviewer}

  })
</script>
<style lang="sass">
  /*https://stackoverflow.com/questions/325273/make-iframe-to-fit-100-of-containers-remaining-height*/
  .WordContainer
    display: flex
    width: 100%
    height: 100%
    flex-direction: column
    overflow: scroll
  .wordContent
    align-content: center
    max-width: 900px

    h1
      font-size: 72px !important
    h2
      font-size: 48px !important
    h3
      font-size: 36px !important
    h4
      font-size: 32px !important
    h5
      font-size: 28px !important
    h6
      font-size: 24px !important

    table, th, td
      border: 1px solid
      border-collapse: collapse

    a
      color: #1976D2

    ins
      background: green
      text-decoration: none

    del
      background: red

  .fullSize

    width: 100%
    height: 100%

  .renderIFrame
    flex-grow: 1
    border: none
    margin: 0
    padding: 0
</style>
