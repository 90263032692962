<template>
  <div class="outerContent">
    <div class="innerContent q-mt-sm">
      <splitpanes class="default-theme col">
        <pane class="leftWebContentPane" :size="container.splitterModel">
          <transition
            appear
            enter-active-class="animated fadeIn"
            leave-active-class="animated fadeOut"
          >
            <div v-show="container.tab !== 'content'">
              <properties
                v-show="container.tab == 'general'"
                :resource="container.revision"
                :compare="container.compareRevision"
              ></properties>

              <metadataview
                v-show="container.tab == 'metadataview'"
                :resource="container.revision"
              >
              </metadataview>
            </div>
          </transition>

          <!--div v-if="node.renderMode" class="iFrameContainer"-->
          <div v-if="container.renderMode" class="iFrameContainer">
            <iframe
              class="renderIFrame"
              style="border: none"
              :srcdoc="container.generateHTML()"
            ></iframe>
          </div>
          <Suspense v-else>
            <component
              v-if="node.hasOwnProperty('resourceId')"
              :is="getViewerType()"
              :instancename="
                'ImproveContent_' + indexPaneIndex.toString() + '_' + nodeIndex
              "
              :file="container.revision"
              :resource="container.revision"
              :compareWith="container.compareRevision"
            />
            <template #fallback>
              <div class="row justify-center q-mt-lg q-mb-lg">
                <q-spinner color="primary" size="3em" :thickness="10" />
              </div>
            </template>
          </Suspense>
        </pane>
      </splitpanes>
    </div>
    <div v-if="container.revision.iWebContext === 'ImproveReview'">
      <Teleport to="#rightSidePaneTarget">
        <div
          v-if="
            container.ComponentHub.isActiveResource(node.treeId, parentPanelId)
          "
        >
          <approvalbar
            v-show="container.revision.userIsReviewer"
            :instancename="'approvalbar' + container.revision.revisionId"
            :resource="container.revision"
          >
          </approvalbar>
          <commentchat
            :instancename="'commentchat' + container.revision.resourceId"
            :resource="container.revision"
            :iwebtype="container.revision.iWebType"
            revisionOnly
          ></commentchat>
        </div>
      </Teleport>
    </div>
    <Teleport to="#contentfoot">
      <revisionbar
        v-if="
          container.ComponentHub.isActiveResource(node.treeId, parentPanelId)
        "
        :instancename="node.resourceId + '_revisionbar'"
        :resource="node"
        @onRevisionChanged="container.onRevisionChanged"
      />
    </Teleport>
    <Teleport to="#contenthead">
      <div
        class="row no-wrap"
        v-if="
          container.ComponentHub.isActiveResource(node.treeId, parentPanelId)
        "
      >
        <q-btn-toggle
          v-model="container.tab"
          class="q-ml-lg"
          flat
          no-caps
          unelevated
          size="sm"
          toggle-color="primary"
          :options="viewers"
        />
      </div>
    </Teleport>
  </div>
</template>

<script lang="coffee">
import { defineComponent, ref, onMounted, reactive, onUnmounted ,defineAsyncComponent} from 'vue'
import {Container} from "./containerCls.coffee"
import { Splitpanes, Pane } from 'splitpanes'
import editorng from 'core/editorng.vue'
import revisionbar from 'plugins/improveweb/revisions/revisionbar.vue'
import properties from 'plugins/improveweb/properties/properties.vue'
import metadataview from 'plugins/improveweb/metadata/metadata.vue'
import pdfviewer from 'plugins/improveweb/pdfview/pdfviewer.vue'
import imageviewer from 'plugins/improveweb/imageview/imageviewer.vue'
import folderviewer from 'plugins/improveweb/folderview/folderview.vue'
import noneviewer from 'plugins/improveweb/noneview/noneviewer.vue'
import stepviewer from 'plugins/improveweb/stepview/stepviewer.vue'
import reviewviewer from 'plugins/improveweb/reviews/reviewviewer.vue'
import commentchat from 'plugins/improveweb/reviews/commentchat.vue'
import approvalbar from 'plugins/improveweb/reviews/approvalbar.vue'
import wordrenderer from 'plugins/improveweb/officeViewers/wordviewer.vue'
import sheetviewer from 'plugins/improveweb/officeViewers/sheetviewer.vue'

export default defineComponent({
  name: 'container',
  components: {
    editorng:editorng,
    Splitpanes,
    Pane,
    revisionbar,
    properties,
    metadataview,
    pdfviewer,
    imageviewer,
    folderviewer,
    noneviewer,
    stepviewer,
    reviewviewer,
    commentchat,
    approvalbar,
    wordrenderer,
    sheetviewer

    }
  props:
    instancename:
      default: 'container'
      type: String
    node:
      default: {}
      type:Object
    nodeIndex:
      type: String
      default: ''
    indexPaneIndex:
      type: String
      default: "0"
    parentPanelId:
      type: Number
      default: 0
    viewers:
      default:[
        {label: 'Content', value: 'content'},
        {label: 'General', value: 'general'},
        {label: 'Metadata', value:'metadataview'},
        #{label: 'Audittrail', value: 'audit trail'},
        #{label: 'History', value:'history'},
        #{label: 'Notes', value:'notes'},
        #{label: 'Rights', value: 'rights'}
        ]
      type: Array
  setup: (props) ->
    container = reactive(new Container({props:props}))
    container.vueBinder()
    onMounted(() => container.mounted())
    onUnmounted(() => container.unmounted())

    getViewerType= ->
      if props.node.hasOwnProperty("resourceId")
        if props.node.getViewerType() is 'image'
          return imageviewer
        else if props.node.getViewerType() is 'text'
          return editorng
        else if props.node.getViewerType() is 'pdf'
          return pdfviewer
        else if props.node.mimeType?
            type = props.node.mimeType.split('/')[1].toLowerCase()
            switch type
              when 'msword', 'vnd.openxmlformats-officedocument.wordprocessingml.document'
                return wordrenderer
              when 'vnd.ms-excel', 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                return sheetviewer
        else if props.node.isFolderish and  props.node.iWebType is 'ImproveResource'
          return folderviewer
        else if props.node.iWebType is 'ImproveStep' or props.node.nodeType is 'Step'
          return stepviewer
        else if props.node.iWebType is 'ImproveReview'
          return reviewviewer
        else
          return noneviewer
      return


    return {container,getViewerType}

})
</script>

<style scoped lang="sass">
.splitpanes.default-theme
    height: 100%
body.body--dark
  .splitpanes.default-theme
    height: 100%
</style>
<style lang="sass">
/*https://stackoverflow.com/questions/325273/make-iframe-to-fit-100-of-containers-remaining-height*/
.iFrameContainer
  display: flex
  width: 100%
  height: 100%
  flex-direction: column
  overflow: hidden
.renderIFrame
  flex-grow: 1
  border: none
  margin: 0
  padding: 0

.my-custom-toggle
  border: 1px solid

.outerContent
  height: 100%
.innerContent
  height: 100%

.containerTabPanels
   height: 100%
.chat
  min-width: 400px
</style>
